import * as React from "react"
import Layout from '../components/layout/layout';
import DisclaimerComponent from '../components/disclaimer/Disclaimer';

import Seo from '../components/seo/seo';

const Disclaimer = () => {
  return <Layout pageInfo={{ pageName: "disclaimer" }}>
    <Seo title="Disclaimer" />

    <DisclaimerComponent></DisclaimerComponent>
  </Layout>
}

export default Disclaimer
