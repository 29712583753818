import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { SmartContract, Nodes, MoneyCpu } from "../../images/icons";
import { Link } from 'gatsby'

import './Services.scss'

const Services = () => {
  return (
    <Container id="services" className="d-flex flex-column section-margin">
      <Row className="justify-content-center">
        <Col md={10} className="d-flex flex-column">
          <Row className="">
            <Col md="auto">
              <h6 className="component-title-secondary">Services</h6>
              <h2 className="component-title">What do we do?</h2>
            </Col>
          </Row>
          <Row>
            <Col md={4} sm={6} xs={10} className="d-flex flex-column mb-4 px-3">
              <div className="icon-container mb-4">

                <SmartContract className="service-icon" />

              </div>
              <div className="service-description">
                <h3 className="mb-4">
                  Smart Contract Development, Testing and Auditing
                </h3>
                <p>
                  We build innovative and secure smart contracts for general
                  blockchain applications.
                </p>
              </div>
            </Col>
            <Col md={4} sm={6} xs={10} className="d-flex flex-column mb-4 px-3">
              <div className="icon-container mb-4">

                <Nodes className="service-icon" />

              </div>
              <div className="service-description">
                <h3 className="mb-4">Blockchain and <br /> DAO Consulting</h3>
                <p>
                  Our vast experience can prove a valuable asset that you can
                  add towards achieving your goals in a more cost and
                  time-efficient way.
                </p>
              </div>
            </Col>
            <Col md={4} sm={6} xs={10} className="d-flex flex-column mb-4 px-3">
              <div className="icon-container mb-4">

                <MoneyCpu className="service-icon" />

              </div>
              <div className="service-description">
                <h3 className="mb-4">Node Deployment and Administration</h3>
                <p>
                  Hassle free infrastructure management, from setting up and
                  maintaining blockchain nodes to configurating development,
                  staging and production environments for your project.
                </p>
              </div>
            </Col>
          </Row>
          {/* <Row md="auto">
            <Button as={Link} to="/services">
              Read more
            </Button>
          </Row> */}
        </Col>
      </Row>
    </Container>
  )
}

export default Services
