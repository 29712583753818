import './App.scss'
import React from 'react'
import Contact from '../contact/Contact'
import Services from '../services/Services'
import Home from '../header/Home'
import About from '../about/About'
import Action from '../action/Action'
import Colaborations from '../colaborations/Colaborations'
import Portofolio from '../portofolio/Portofolio'

function App() {
  return (
    <div className="App" style={{ overflowX: 'hidden' }}>
      <Home />
      <Services />
      <About />
      <Portofolio />
      <Action />
      <Contact />
    </div>
  )
}

export default App
