import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import FiBotLogo from '../../images/FiBot-Icon-Black.svg';
import HPAYLogo from '../../images/HPay-Logo.png';
import IHelpLogo from '../../images/ihelp.svg';
import Partener from "../partener/Partener";
import './Portofolio.scss';

const Portofolio = () => {
    return (
        <Container id="portofolio" className="section-margin">
            <Row className="text-center">
                <Col>
                    <h6 className="component-title-secondary"> Colaborations </h6>
                    <h2 className="component-title d-flex justify-content-center">Project Contributions</h2>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Partener href={'https://fibot.xyz'} logo={FiBotLogo}></Partener>


                <Partener href={'https://hedgepay.org'} logo={HPAYLogo}></Partener>
                <Partener href={'https://ihelp.finance'} logo={IHelpLogo}></Partener>

            </Row>
        </Container>
    )
}

export default Portofolio
