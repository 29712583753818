import * as React from "react"
import App from '../components/app/App'
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import Gradient from '../images/svg/gradient.inline.svg'


const Lights = () => <>
  <div id="home-light" className="light light-top-left">
    <Gradient style={{
      position: 'relative',
      right: '35%',
      top: '0',
      maxWidth: '100%'

    }} />
  </div>
  <div id="home-light" className="light light-top-right">
    <Gradient style={{
      position: 'relative',
      left: '35%',
      top: '70%',
      maxWidth: '100%'
    }} />
  </div>

  <div id="services-light" className="light light-top-right">
    <Gradient style={{
      position: 'relative',
      left: '20%',
      top: '40%',
      maxWidth: '100%'
    }} />
  </div>

  <div id="about-light" className="light ">
    <Gradient style={{
      position: 'relative',
      right: '30%',
      top: '40%',
      maxWidth: '100%'
    }} />
  </div>


  <div id="action-light" className="light light-top-right">
    <Gradient style={{
      position: 'relative',
      left: '20%',
      top: '40%',
      maxWidth: '100%'
    }} />
  </div>

  <div id="contact-light" className="light light-top-right">
    <Gradient style={{
      position: 'relative',
      left: '-10%',
      top: '40%',
      maxWidth: '100%'
    }} />
  </div>
</>

// markup
const IndexPage = () => {
  return <Layout pageInfo={{ pageName: "index" }}>
    <Lights />
    <Seo title="Home" />
    <App />
  </Layout>
}

export default IndexPage
