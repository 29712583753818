import React from 'react';
import "./Partener.scss";

const Partener = ({ logo, href }) => (

    <a style={{ flex: 1 }} className="text-center my-4" href={href} target="_blank" rel="noopener noreferrer">
        <img src={logo}
            className="partener-logo" alt="name">
        </img >
    </a>


)

export default Partener;