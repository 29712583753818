import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ContactForm from './ContactForm'

const Contact = () => {
  return (
    <Container fluid id="contact">
      <Container>
        <Row className="align-items-center py-5">
          <Col md={7} className="d-flex flex-column justify-content-center">
            <h2 className="mb-4">
              Have a project?
              <br />
              We'd love to hear about it
            </h2>
            <div className="contact-icons">
              <FontAwesomeIcon icon={['fas', 'fa-envelope']} />
              <a className="ms-2" href="mailto:hello@0xversion.com">
                {' '}
                hello@0xversion.com
              </a>
              <br />
              <FontAwesomeIcon icon={['fas', 'fa-phone']} />

              <a className="ms-2" href="tel:62082139279172<">
                +62-0821-3927-9172
              </a>
            </div>
          </Col>
          <Col md={5} className="justify-content-center">
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default Contact
