import axios from 'axios'
import React, { useState } from 'react'
import { Button, Form, FloatingLabel, Row, Col } from 'react-bootstrap'
import FileUploader from './FileUpload'
import './Form.scss'

const ContactForm = () => {
  const [validated, setValidated] = useState(false)

  // Input Change Handling
  const [inputs, setInputs] = useState({
    email: "",
    subject: "",
    message: "",
  });

  const handleOnChange = (event) => {
    event.persist();
    setInputs((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    console.log(inputs)

    axios({
      method: "POST",
      url: "https://formbold.com/s/910z6",
      data: inputs,
    })
      .then((r) => {
        console.log("hello");
      })
      .catch((r) => {
        console.log("error");
      });


    setValidated(true)
  }

  return (
    <Form
      className="login-box"
      noValidate
      validated={validated}
      // onSubmit={handleSubmit}
      action="https://formbold.com/s/910z6"
      method="POST"
    >
      <FloatingLabel
        className="user-box"
        label="Name"
      >
        <Form.Control
          id="subject"
          name="subject"
          type="text"
          // onChange={handleOnChange}
          // value={inputs.subject} 
          required
          placeholder="Name" />
      </FloatingLabel>

      <FloatingLabel
        className="user-box"
        label="Email address"
      >
        <Form.Control
          id="email"
          name="email"
          type="email"
          // onChange={handleOnChange}
          // value={inputs.email}
          required placeholder="Email address" />

        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          Please provide a valid email address.
        </Form.Control.Feedback>
      </FloatingLabel>

      <FloatingLabel
        className="mb-3 user-box"
        label="Message"
      >
        <Form.Control as="textarea"
          // onChange={handleOnChange}
          id="message"
          name="message"
          // value={inputs.message} 
          rows={3}
          placeholder="Message" />
      </FloatingLabel>

      <Row className="mb-3 g-0">
        <Col className="d-flex">
          <Button type="submit">Send request</Button>
        </Col>
      </Row>
    </Form>
  )
}

export default ContactForm
