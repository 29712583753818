import * as React from "react"
import { Container } from 'react-bootstrap';
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';

// markup
const Services = () => {
  return <Layout pageInfo={{ pageName: "services" }}>
    <Seo title="Services" />
    <Container>
      <div className='section-margin'>
        <h1>
          Our services
        </h1>

      </div>
    </Container>

  </Layout>
}

export default Services
