import React from 'react'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import image from '../../images/0xversionidea.svg'
import './Action.scss'

const Action = () => {
  return (
    <Container id="action" className="section-margin">
      <Row>
        <Col md={7}>
          <h6 className="component-title-secondary">Let's Talk</h6>
          <h2 className="component-title">Free Consultation</h2>
          <p className="pb-2">
            During your 30-minute free consultation, we will analyze your
            business processes and needs with you, understand your long term
            vision and identify how we can help you on your journey.
          </p>
          <p className="pb-3">
            Please book an appointment using the Calendly link and we will be
            more than happy to talk to you.
          </p>
          <Button href="https://calendly.com/defitect/30min" target="_blank">
            Book now
          </Button>
        </Col>
        <Col md={5}>
          <Image fluid src={image}></Image>
        </Col>
      </Row>
    </Container>
  )
}

export default Action
