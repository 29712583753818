import React from 'react'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import './Home.scss'
import image from '../../images/0xversionBG.svg'

const Home = () => {
  return (
    <Container fluid className="main-content overflow-hidden p-0">
      <Container id="home" className="d-flex justify-content-center">
        <Row className="justify-content-center align-items-center ">
          <Col md={8} className="header-text">
            <h1 className="pb-4">
              Smart contracts development, auditing, and consulting
            </h1>
            <h6 className="pb-5">
              Custom solutions for building your decentralised applications.
            </h6>
            <Button
              href="https://calendly.com/defitect/30min"
              target="_blank"
              className="header-button learn-more"
            >
              <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
              </span>
              <span className="button-text">Book a free call</span>
            </Button>
          </Col>
        </Row>
      </Container>
      <Image fluid src={image}></Image>
    </Container>
  )
}

export default Home
